import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Article from '../components/article'
import Section from '../components/section'
import { H3 } from '../components/globals'
import { Text, Stack } from '@chakra-ui/core'

function ReservationPolicy() {
  return (
    <Layout title="Reservation Policy">
      <SEO title="Reservation Policy" />

      <Article>
        <Section>
          <Stack spacing={6}>
            <H3>POLÍTICA DE RESERVACIÓN</H3>
            <Text>CHECK-IN: 3:00 PM HRS – CHECK-OUT 12:00 HRS</Text>

            <H3>POLÍTICA DE CANCELACIÓN</H3>
            <Text>
              Hay una penalización de 1 noche por cancelaciones en cualquier momento y hasta 15 días
              antes de la fecha de llegada. Cancelar 7 días antes de la fecha de llegada y no
              presentarse causa una penalización del 100%. No se aplica ningún reembolso en las
              salidas anticipadas. No se aplican reembolsos por cancelaciones en las reservas de
              estancias del 22 de diciembre al 5 de enero. Estas políticas no se aplican para bodas
              y/o grupos de viaje. Pueden aplicarse políticas especiales según el contrato.
            </Text>
          </Stack>
        </Section>
      </Article>
    </Layout>
  )
}

export default ReservationPolicy
